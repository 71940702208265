/* 7 - mobile app -->> home page - start
==================================================================================================== */

/* 7.1 - home page 1 - start
================================================== */

/* banner-section - start */
.banner-section {
  .mobile-app-banner-1 {
    z-index: 1;
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 145px;
    .banner-content {
      z-index: 1;
      padding-top: 185px;
      > h2 {
        font-size: 47px;
        line-height: 60px;
        margin-bottom: 30px;
        font-weight: $fw-bold;
        text-transform: initial;
      }
      > p {
        font-size: 16px;
        margin-bottom: 70px;
        color: $default-color;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }

    .banner-image {
      padding-top: 25px;
      position: relative;
      padding-bottom: 70px;
      .bg-image {
        top: unset !important;
        left: unset !important;
        position: static;
        width: 685px;
        margin-right: -210px;
      }
      .phone-image {
        z-index: 1;
        right: 40px;
        width: 275px;
        position: absolute;
        top: 155px !important;
        left: unset !important;
      }
      .man-image-1 {
        z-index: 1;
        bottom: 0px;
        width: 255px;
        position: absolute;
        left: 0px !important;
        top: unset !important;
      }
      .man-image-2 {
        z-index: 1;
        bottom: 0px;
        width: 240px;
        right: -155px;
        position: absolute;
        top: unset !important;
        left: unset !important;
      }
      .man-image-3 {
        z-index: 1;
        right: -35px;
        width: 135px;
        position: absolute;
        top: 0px !important;
        left: unset !important;
      }
      .cog-image {
        width: 60px;
        position: absolute;
        top: 90px !important;
        left: 55% !important;
        transform: translateX(-55%);
        animation: spin 10s infinite linear;
      }
      .leaf-image-1 {
        z-index: 0;
        width: 165px;
        position: absolute;
        top: 105px !important;
        left: 30px !important;
      }
      .leaf-image-2 {
        z-index: 0;
        right: -76px;
        width: 175px;
        position: absolute;
        top: 245px !important;
        left: unset !important;
      }
    }

    .shape-1 {
      top: 470px !important;
      left: 225px !important;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-2 {
      top: 200px !important;
      left: 400px !important;
      position: absolute;
      animation: zoominout 1.5s alternate infinite ease-in;
    }

    .shape-3 {
      top: unset !important;
      left: 500px !important;
      bottom: 100px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-4 {
      top: 245px !important;
      left: 675px !important;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-5 {
      top: unset !important;
      left: 885px !important;
      bottom: 80px;
      position: absolute;
      animation: zoominout 1.5s alternate infinite ease-in;
    }
    .shape-6 {
      top: unset !important;
      left: 940px !important;
      bottom: 300px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-7 {
      top: 205px !important;
      left: 1000px !important;
      position: absolute;
      animation: zoominout 1.5s alternate infinite ease-in;
    }
  }
}
.home-page-7 .banner-section {
  position: relative;
}
.home-page-7 .banner-section:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 18%;
}
/* banner-section - end */

/* client-section - start */
.home-page-1 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -30px;
  }
}
/* client-section - end */

/* working-process-section - start */
.working-process-section {
  .process-steps-list {
    > ul {
      width: 100%;
      display: flex;
      position: relative;
      &:before {
        top: 63px;
        left: 50%;
        width: 75%;
        height: 1px;
        content: "";
        position: absolute;
        transform: translateX(-50%);
        background-color: $deep-gray;
      }
      > li {
        flex: 1;
        z-index: 1;
        text-align: center;
        .item-icon {
          width: 130px;
          height: 130px;
          padding: 30px;
          text-align: center;
          border-radius: 100%;
          margin-bottom: 50px;
          border: 1px solid $deep-gray;
          background-color: $pure-white;
          transition: $default-transition;
        }
        .item-title {
          font-size: 18px;
          color: $default-color;
          font-weight: $fw-medium;
          text-transform: capitalize;
          transition: $default-transition;
        }
        &:hover {
          .item-icon {
            border-color: $pure-white;
            box-shadow: 0px 15px 50px 0px rgba(0, 27, 103, 0.15);
          }
          .item-title {
            color: $default-black;
          }
        }
      }
    }
  }
}
/* working-process-section - end */

/* extra-features-section - start */
.extra-features-section {
  /* features-nav - start */
  .features-nav {
    padding-bottom: 50px;
    border-bottom: 2px solid $pure-white;
    > .nav {
      width: 100%;
      border-bottom: 0px;
      > .nav-item {
        width: 33.333%;
        text-align: center;
        > .nav-link {
          color: #272d3a;
          font-size: 21px;
          text-align: center;
          font-weight: $fw-bold;
          background: transparent;
          border: 0px;
          .item-icon {
            width: 80px;
            height: 80px;
            display: table;
            margin: 0 auto;
            text-align: center;
            margin-bottom: 20px;
          }
          &:hover {
            color: $default-blue;
          }
        }
        > .nav-link.active {
          color: $default-blue;
        }
      }
    }
  }
  /* features-nav - end */

  .tab-content {
    .tab-pane {
      .feature-content {
        font-size: 16px;
        .item-title {
          font-size: 30px;
          font-weight: $fw-regular;
          .break-point {
            width: 100%;
            display: table;
          }
        }
        > p {
          font-size: 16px;
        }
        .instructions-list {
          > ul {
            > li {
              font-size: 14px;
              margin-top: 15px;
              > i {
                margin-right: 15px;
                color: $default-blue;
              }
            }
          }
        }
      }
    }
  }
}
/* extra-features-section - end */

/* features-section - start */
.features-section {
  .feature-item {
    margin-bottom: 160px;
    &:last-child {
      margin-bottom: 0px;
    }
    .feature-image-1 {
      z-index: 1;
      padding-top: 45px;
      position: relative;
      padding-bottom: 25px;
      .circle-image {
        z-index: -1;
        width: 565px;
        margin-left: -100px;
        animation: spin 20s infinite linear;
      }
      .phone-image {
        top: -38px;
        left: -32px;
        z-index: 1;
        width: 438px;
        position: absolute;
      }
      .comment-image-1,
      .comment-image-2 {
        z-index: 2;
        width: 400px;
        position: absolute;
      }
      .comment-image-1 {
        left: 27px;
        top: 135px;
      }
      .comment-image-2 {
        left: 65px;
        bottom: 10px;
      }
    }

    .feature-content {
      font-size: 16px;
      padding-top: 70px;
      .section-title {
        .sub-title {
          padding: 5px;
          padding-left: 20px;
          border-left: 3px solid $default-pink;
        }
        .title-text {
          font-size: 36px;
          font-weight: $fw-medium;
        }
      }
      > p {
        font-size: 16px;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 40px;
            &:last-child {
              margin-right: 0px;
            }
            .custom-btn-underline {
              margin: 18px 0px;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      .feature-image-1 {
        padding-top: 57px;
        .circle-image {
          margin-left: 0px;
          margin-right: -80px;
        }
        .phone-image {
          top: -29px;
          left: unset;
          right: -30px;
        }
        .comment-image-1,
        .comment-image-2 {
          width: 420px;
        }
        .comment-image-1 {
          top: 30px;
          left: 0px;
        }
        .comment-image-2 {
          left: unset;
          bottom: 35px;
          right: -136px;
        }
      }
    }
  }
}
/* features-section - end */

/* service-section - start */
.service-section {
  .container {
    max-width: 1310px;
  }
  .section-title {
    .paragraph-text {
      width: 707px;
      margin: 0 auto;
      font-size: 24px;
      line-height: 36px;
      color: $default-black;
    }
  }

  .service-carousel {
    position: relative;
    .swiper-container {
      position: static;
      padding-top: 40px;
      padding-bottom: 50px;
    }
    .item {
      .service-item {
        width: 100%;
        height: 100%;
        display: block;
        text-align: center;
        padding: 60px 30px;
        border: 1px solid $deep-gray;
        background-color: transparent;
        .item-icon {
          width: 105px;
          height: 105px;
          display: table;
          font-size: 36px;
          color: $pure-white;
          position: relative;
          text-align: center;
          margin: 0 auto 30px;
          background-repeat: no-repeat;
          background-position: center center;
          > i {
            top: 65%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -65%);
          }
        }
        .item-title {
          width: 100%;
          font-size: 18px;
          color: $default-color;
          font-weight: $fw-medium;
          text-transform: capitalize;
        }

        &:hover {
          background-color: $pure-white;
          box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
          .item-title {
            color: $default-black;
          }
        }
      }
    }
    .owl-item.active.center {
      .item {
        .service-item {
          background-color: $pure-white;
          box-shadow: 0px 5px 50px 3px rgba(0, 27, 103, 0.1);
          .item-title {
            color: $default-black;
          }
        }
      }
    }
  }

  .owl-theme {
    .owl-dots {
      margin-top: 0px !important;
    }
  }
}
/* service-section - end */

/* funfact-section - start */
.funfact-section {
  .bg-image {
    padding: 50px 0px;
    background-size: 60% 100%;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .section-title {
    .title-text {
      width: 695px;
      display: table;
      margin: 0 auto;
      line-height: 48px;
      font-weight: $fw-light;
    }
  }

  .counter-items-list {
    > ul {
      > li {
        margin-right: 130px;
        &:last-child {
          margin-right: 0px;
        }
        > h4 {
          font-size: 48px;
          color: $default-color;
          font-weight: $fw-light;
        }
        .counter-title {
          font-size: 20px;
          color: $default-black;
          font-weight: $fw-medium;
          text-transform: capitalize;
        }
      }
    }
  }

  .review-btn {
    .custom-btn-underline {
      font-size: 18px;
    }
  }
}
/* funfact-section - end */

/* app-section - start */
.app-section {
  .app-content {
    padding-top: 180px;
    .section-title {
      .title-text {
        line-height: 48px;
        font-weight: $fw-medium;
      }
    }
    .btns-group {
      > ul {
        li {
          margin-right: 30px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }

  .app-image {
    z-index: 1;
    position: relative;
    padding-top: 64px;
    padding-bottom: 64px;
    .phone-image {
      top: -26px;
      z-index: 1;
      right: -10px;
      width: 456px;
      position: absolute;
    }
    .circle-image {
      z-index: -1;
      width: 565px;
      margin-right: -47px;
      animation: spin 20s infinite linear;
    }
  }
}
/* app-section - end */

/* faq-section - start */
.faq-section {
  .queistions-container {
    .queistions-item {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0px;
      }
      .item-title {
        font-size: 18px;
      }
    }
  }
  .contact-info {
    > p {
      width: 330px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 26px;
      color: $default-black;
      font-weight: $fw-medium;
      > a {
        color: $default-blue;
        text-transform: lowercase;
      }
    }
  }
}
/* faq-section - end */

/* footer-section - start */
.home-page-1 {
  .footer-section {
    background-size: 100% 75%;
  }
}
/* footer-section - end */

/* 7.1 - home page 1 - end
================================================== */

/* 7.2 - home page 2 - start
================================================== */

/* header-section - start */
.home-page-2 {
  .header-section.stuck {
    &:before {
      background: linear-gradient(to right, #0eb9f8, #75e6ca);
    }
  }
}
/* header-section - end */

/* banner-section - start */
.banner-section {
  .mobile-app-banner-2 {
    width: 100%;
    height: 850px;
    position: relative;
    padding-top: 170px;
    margin-bottom: 156px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    .shape-image-1 {
      top: 0px;
      left: 0px;
      width: 472px;
      position: absolute;
    }
    .shape-image-2 {
      left: 926px;
      bottom: 80px;
      position: absolute;
      animation: zoominout 2s alternate infinite ease-in;
      @keyframes zoominout {
        0% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1.05);
        }
      }
    }
    .shape-image-3 {
      top: 202px;
      left: 894px;
      position: absolute;
      animation: spin 15s infinite linear;
    }
    .shape-image-4 {
      top: 268px;
      right: 209px;
      position: absolute;
      animation: spin 15s infinite linear;
    }

    .banner-content {
      padding-top: 150px;
      > h2 {
        font-size: 47px;
        line-height: 60px;
        color: $pure-white;
        margin-bottom: 30px;
        font-weight: $fw-bold;
        text-transform: initial;
      }
      > p {
        font-size: 16px;
        color: $pure-white;
        margin-bottom: 70px;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .banner-image {
      z-index: 1;
      position: relative;
      padding-top: 130px;
      .phone-image {
        z-index: 1;
        width: 360px;
        margin: 0 auto;
        display: table;
        img {
          width: 100%;
          display: block;
        }
      }
      .man-image-1 {
        z-index: 1;
        width: 155px;
        text-align: center;
        position: absolute;
        left: 0px !important;
        bottom: 0px !important;
        top: unset !important;
      }
      .man-image-2 {
        z-index: 1;
        width: 215px;
        position: absolute;
        right: 0px !important;
        bottom: 0px !important;
        top: unset !important;
        left: unset !important;
      }
      .message-image {
        z-index: 1;
        width: 140px;
        position: absolute;
        left: 38% !important;
        top: 270px !important;
      }
      .leaf-image-1 {
        z-index: -1;
        width: 205px;
        position: absolute;
        top: 20px !important;
        left: 40px !important;
      }
      .leaf-image-2 {
        z-index: -1;
        width: 205px;
        position: absolute;
        transform: rotate(-20deg);
        top: 80px !important;
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}
/* banner-section - end */

/* client-section - start */
.home-page-2 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -20px;
  }
}
/* client-section - end */

/* features-section - start */
.home-page-2 {
  .features-section {
    .feature-item {
      .feature-content {
        padding-top: 100px;
      }
    }
  }
}

.features-section {
  .feature-item {
    .feature-image-2 {
      position: relative;
      .popup-video {
        z-index: 1;
        right: 50px;
        bottom: 130px;
        width: 95px;
        height: 95px;
        font-size: 24px;
        line-height: 95px;
        position: absolute;
        text-align: center;
        border-radius: 100%;
        color: $default-pink;
        background-color: $pure-white;
        animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
        &:before {
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: -1;
          content: "";
          position: absolute;
          border-radius: 100%;
          box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
        }
      }
      .item-image {
        width: 570px;
        margin-left: -145px;
      }
    }

    .feature-content {
      .feature-item-title {
        font-size: 36px;
        margin-bottom: 45px;
        font-weight: $fw-medium;
        text-transform: initial;
      }

      .service-list {
        > ul {
          > li {
            margin-top: 40px;
            .item-icon {
              z-index: 1;
              float: left;
              width: 60px;
              height: 60px;
              font-size: 24px;
              line-height: 60px;
              color: $pure-white;
              text-align: center;
              margin-right: 30px;
              background-repeat: no-repeat;
              background-position: center center;
            }
            .item-content {
              display: table;
              .item-title {
                font-size: 21px;
              }
            }
          }
        }
      }

      .info-list {
        > ul {
          > li {
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0px;
            }
            .icon {
              float: left;
              font-size: 18px;
              margin-right: 30px;
              color: $default-blue;
            }
            .info-text {
              display: table;
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
      }
    }

    .feature-image-2 {
      .bg-image {
        z-index: -1;
        width: 570px;
        margin-top: 115px;
        position: relative;
        margin-right: -130px;
      }
      .phone-image {
        top: -25px;
        right: -50px;
        width: 430px;
        position: absolute;
      }
      .comment-image-1,
      .comment-image-2 {
        width: 400px;
        position: absolute;
      }
      .comment-image-1 {
        top: 160px;
        right: -105px;
      }
      .comment-image-2 {
        right: -120px;
        bottom: 112px;
      }
      .shape-image-1 {
        top: 15px;
        left: 90px;
        z-index: -1;
        position: absolute;
      }
      .shape-image-2 {
        top: 50%;
        z-index: -1;
        right: -50px;
        position: absolute;
      }
      .shape-image-3 {
        z-index: -1;
        left: 110px;
        bottom: 20px;
        position: absolute;
        transform: rotate(-130deg);
      }
    }

    &:nth-child(2) {
      .feature-image-2 {
        .bg-image {
          margin-right: 0px;
          margin-left: -130px;
        }
        .phone-image {
          left: -50px;
          right: unset;
        }
        .comment-image-1,
        .comment-image-2 {
          width: 400px;
          position: absolute;
        }
        .comment-image-1 {
          top: 50px;
          left: -130px;
          right: unset;
        }
        .comment-image-2 {
          left: 65px;
          right: unset;
        }
        .shape-image-1 {
          left: -60px;
        }
        .shape-image-2 {
          right: 80px;
        }
        .shape-image-3 {
          left: -30px;
        }
      }
    }
  }
}
/* features-section - end */

/* app-section - strat */
.app-section {
  .app-image-2 {
    position: relative;
    padding-top: 115px;
    .bg-image {
      width: 570px;
      margin-right: -60px;
    }
    .phone-image {
      top: -36px;
      right: 0px;
      z-index: 1;
      width: 456px;
      position: absolute;
    }
    .shape-image-1 {
      left: 60px;
      top: 140px;
      width: 83px;
      position: absolute;
    }
    .shape-image-2 {
      right: 13px;
      top: 375px;
      width: 83px;
      position: absolute;
    }
    .shape-image-3 {
      left: 62px;
      width: 90px;
      bottom: 15px;
      position: absolute;
    }
  }
}
/* app-section - end */

/* 7.2 - home page 2 - end
================================================== */

/* 7.3 - home page 3 - start
================================================== */

.home-page-3 {
  padding-top: 124px;
}

/* header-section - start */
.home-page-3 {
  .header-section {
    padding: 35px 0px;
    .btns-group {
      .custom-btn {
        background-color: $default-blue;
        &:before {
          background-color: $default-pink;
        }
      }
    }
  }

  .sticky-header.stuck {
    padding: 30px 0px;
  }
}
/* header-section - end */

/* banner-section - start */
.slider-section {
  .mobileapp-main-carousel {
    width: 1750px;
    margin: 0 auto;
    box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.2);
    .item {
      height: 900px;
      position: relative;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .item.first-item {
      overflow: hidden;
      padding-top: 150px;
      h3 {
        height: 35px;
        display: table;
        font-size: 14px;
        padding: 0px 25px;
        line-height: 37px;
        text-align: center;
        color: $pure-white;
        margin: 0 auto 20px;
        font-weight: $fw-medium;
        text-transform: capitalize;
        border: 1px solid rgba(255, 255, 255, 0.5);
        background-color: rgba(255, 255, 255, 0.1);
      }
      h2 {
        font-size: 36px;
        line-height: 48px;
        color: $pure-white;
        margin-bottom: 50px;
        font-weight: $fw-medium;
      }
      .subscribe-form {
        margin-bottom: 50px;
        > p {
          font-size: 14px;
          color: $pure-white;
          font-weight: $fw-medium;
        }
        .form-item {
          width: 430px;
          display: table;
          margin: 0 auto 20px;
          input {
            height: 60px;
            padding: 0px 30px;
          }
          .submit-btn {
            top: 50%;
            right: 5px;
            height: 50px;
            font-size: 16px;
            line-height: 50px;
            padding: 0px 30px;
            color: $pure-white;
            position: absolute;
            border-radius: 3px;
            font-weight: $fw-medium;
            transform: translateY(-50%);
            background-color: $default-blue;
          }
        }
      }
      .phone-image {
        width: 355px;
        display: table;
        margin: 0 auto 0px;
      }
    }

    .item.second-item {
      padding-top: 100px;
      .slider-content {
        padding-top: 172px;
        .title-text {
          font-size: 47px;
          color: $pure-white;
        }
        .paragraph-text {
          font-size: 16px;
          line-height: 26px;
          color: $pure-white;
        }
        .newsletter-form {
          width: 425px;
          .form-item {
            margin-bottom: 20px;
            .email-input {
              width: 100%;
              height: 60px;
              display: table;
              padding: 0px 30px;
              border-radius: 3px;
              border: 1px solid $deep-gray;
              background-color: $pure-white;
            }
            .form-item-btn {
              top: 50%;
              right: 8px;
              width: auto;
              height: 45px;
              line-height: 45px;
              padding: 0px 40px;
              color: $pure-white;
              text-align: center;
              border-radius: 3px;
              position: absolute;
              transform: translateY(-50%);
            }
          }
        }
      }
      .slider-image {
        position: relative;
        .phone-image {
          width: 425px;
          top: unset !important;
          left: unset !important;
          position: unset !important;
        }
        .commentbar-image-1,
        .commentbar-image-2 {
          width: 400px;
          position: absolute;
        }
        .commentbar-image-1 {
          top: 73px !important;
          left: -92px !important;
        }
        .commentbar-image-2 {
          top: 440px !important;
          left: -92px !important;
        }
      }
    }
  }

  .swiper-pagination-bullets {
    top: 50%;
    right: 30px;
    margin-top: 0px;
    left: auto;
    width: auto;
    bottom: auto;
    transform: translateY(-50%);
    .swiper-pagination-bullet {
      display: table;
      margin: 10px 0px;
      background: rgba(255, 255, 255, 0.5);

      &:hover::before {
        opacity: 1;
        background-color: $pure-white;
      }

      &.swiper-pagination-bullet-active::before {
        opacity: 1;
        background-color: $pure-white;
      }
    }
  }
}
/* banner-section - end */

/* client-section - start */
.home-page-3 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -30px;
  }
}
/* client-section - end */

/* features-section - start */
.features-section {
  .feature-item {
    .feature-image-3 {
      position: relative;
      padding-top: 156px;
      .bg-image {
        width: 580px;
        margin-left: -70px;
        margin-right: -40px;
      }
      .phone-image {
        z-index: 1;
        left: 52px;
        top: 113px;
        width: 303px;
        position: absolute;
      }
      .man-image-1 {
        z-index: 2;
        left: -15px;
        bottom: 24px;
        width: 139px;
        position: absolute;
      }
      .man-image-2 {
        z-index: 2;
        right: 0px;
        bottom: 24px;
        width: 189px;
        position: absolute;
      }
      .comment-image {
        z-index: 2;
        top: 228px;
        left: 138px;
        width: 120px;
        position: absolute;
      }
      .leaf-image-1 {
        top: 0px;
        left: -5px;
        width: 180px;
        position: absolute;
      }
      .leaf-image-2 {
        top: 55px;
        right: 40px;
        width: 180px;
        position: absolute;
      }
    }
    &:nth-child(2) {
      .feature-image-3 {
        padding-top: 110px;
        .bg-image {
          margin: 0px;
          width: 554px;
          margin-left: -84px;
        }
        .phone-image {
          top: 116px;
          left: 85px;
          width: 246px;
          position: absolute;
        }
        .man-image-1 {
          left: -26px;
          bottom: 0px;
          width: 212px;
          position: absolute;
        }
        .man-image-2 {
          right: 0px;
          width: 195px;
          bottom: -4px;
          position: absolute;
        }
        .leaf-image-1 {
          top: 27px;
          left: -12px;
          width: 212px;
          position: absolute;
        }
        .leaf-image-2 {
          top: 0px;
          right: 57px;
          width: 180px;
          position: absolute;
        }
      }
    }
  }
}
/* features-section - end */

/* 7.3 - home page 3 - end
================================================== */

/* 7.4 - home page 4 - start
================================================== */

/* banner-section - start */
.banner-section {
  .mobile-app-banner-4 {
    z-index: 1;
    width: 100%;
    height: 100vh;
    padding-top: 260px;
    position: relative;
    .bg-shape-image {
      top: 0px;
      right: 0px;
      width: 58%;
      z-index: -1;
      position: absolute;
    }
    .banner-content {
      z-index: 1;
      padding-top: 100px;
      > h2 {
        font-size: 47px;
        line-height: 60px;
        margin-bottom: 30px;
        font-weight: $fw-bold;
        text-transform: initial;
      }
      > p {
        font-size: 16px;
        margin-bottom: 70px;
        color: $default-color;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .banner-image {
      padding-top: 195px;
      padding-bottom: 50px;
      .phone-image {
        width: 730px;
        margin-right: -165px;
        top: unset !important;
        left: unset !important;
        position: static !important;
      }
      .man-image-1 {
        width: 280px;
        position: absolute;
        top: 60px !important;
        left: 40px !important;
      }
      .sms-image-1 {
        width: 215px;
        position: absolute;
        top: 163px !important;
        left: 186px !important;
      }
      .man-image-2 {
        z-index: 1;
        width: 330px;
        position: absolute;
        top: 40px !important;
        left: unset !important;
        right: -165px !important;
      }
      .sms-image-2 {
        width: 200px;
        position: absolute;
        top: 0px !important;
        left: unset !important;
        right: -100px !important;
      }
      .tree-image {
        bottom: 0px;
        right: 60px;
        width: 200px;
        position: absolute;
        top: unset !important;
        left: unset !important;
      }
    }
  }
}
/* banner-section - end */

/* features-section - start */
.features-section {
  .feature-item {
    .feature-image-4 {
      z-index: 1;
      position: relative;
      padding-bottom: 55px;
      .phone-image {
        z-index: 1;
        width: 300px;
        display: table;
      }
      .floor-image {
        z-index: -1;
        bottom: 5px;
        width: 635px;
        left: -200px;
        position: absolute;
      }
      .man-image-1 {
        z-index: 2;
        bottom: 55px;
        left: -220px;
        width: 240px;
        position: absolute;
      }
      .flower-image-2 {
        width: 100px;
        bottom: 55px;
        right: -130px;
        position: absolute;
      }
      .comment-1-image {
        top: 45px;
        z-index: 1;
        left: -150px;
        width: 290px;
        position: absolute;
      }
      .comment-2-image {
        top: 160px;
        left: -30px;
        z-index: 1;
        width: 285px;
        position: absolute;
      }
      .shape-image-1 {
        bottom: 55px;
        left: -135px;
        position: absolute;
      }
      .shape-image-2 {
        top: 40%;
        z-index: -1;
        right: -60px;
        position: absolute;
      }
    }

    &:nth-child(2) {
      .feature-image-4 {
        padding-bottom: 80px;
        .box-image-1 {
          z-index: 1;
          top: 50px;
          left: 40px;
          width: 80px;
          position: absolute;
        }
        .box-image-2 {
          z-index: 1;
          top: 150px;
          right: 45px;
          width: 80px;
          position: absolute;
        }
        .box-image-3 {
          z-index: 1;
          top: 250px;
          left: 40px;
          width: 80px;
          position: absolute;
        }
        .man-image-1 {
          bottom: 0px;
          left: unset;
          right: -45px;
          width: 185px;
          position: absolute;
        }
        .man-image-2 {
          z-index: 1;
          bottom: 0px;
          left: -225px;
          width: 365px;
          position: absolute;
        }
        .flower-image {
          width: 110px;
          right: -110px;
          bottom: 100px;
          position: absolute;
        }
      }
    }
  }
}
/* features-section - end */

/* 7.4 - home page 4 - end
================================================== */

/* 7.5 - home page 5 - start
================================================== */

/* header-section - start */
.home-page-5 {
  .header-section.stuck {
    &:before {
      background: linear-gradient(to right, #ff4697, #ffb87e);
    }
  }
}
/* header-section - end */

/* banner-section - start */
.banner-section {
  .mobile-app-banner-5 {
    width: 100%;
    height: auto;
    position: relative;
    padding-top: 200px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: top center;
    .banner-content {
      padding-top: 80px;
      .title-text {
        font-size: 47px;
        color: $pure-black;
      }
      .paragraph-text {
        font-size: 16px;
        line-height: 26px;
        color: $pure-black;
      }
      .newsletter-form {
        width: 425px;
        .form-item {
          margin-bottom: 20px;
          .email-input {
            width: 100%;
            height: 60px;
            display: table;
            padding: 0px 30px;
            border-radius: 3px;
            border: 1px solid $deep-gray;
            background-color: $pure-white;
          }
          .form-item-btn {
            top: 50%;
            right: 8px;
            width: auto;
            height: 45px;
            line-height: 45px;
            padding: 0px 40px;
            color: $pure-white;
            text-align: center;
            border-radius: 3px;
            position: absolute;
            transform: translateY(-50%);
          }
        }
      }
    }
    .banner-image {
      width: 100%;
      height: 100%;
      position: relative;
      .phone-image {
        width: 425px;
        top: unset !important;
        left: unset !important;
        position: unset !important;
        // animation: updown 2s linear infinite;
      }
      .commentbar-image-1,
      .commentbar-image-2 {
        width: 400px;
        position: absolute;
      }
      .commentbar-image-1 {
        right: 185px;
        top: 73px !important;
        left: unset !important;
      }
      .commentbar-image-2 {
        right: 150px;
        top: 445px !important;
        left: unset !important;
      }
    }
  }
}
/* banner-section - end */

/* client-section - start */
.home-page-5 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -30px;
  }
}
/* client-section - end */

/* app-section - start */
.app-section {
  .app-image {
    .bg-image {
      z-index: -1;
      width: 100%;
    }
    .shape-image-1 {
      top: 155px;
      left: 135px;
      z-index: -1;
      position: absolute;
    }
    .shape-image-2 {
      top: 53%;
      right: 0px;
      z-index: -1;
      position: absolute;
    }
    .shape-image-3 {
      z-index: -1;
      left: 145px;
      bottom: 45px;
      position: absolute;
    }
  }
}
/* app-section - end */

/* faq-section - start */
.faq-section {
  .faq-tabs-nav {
    width: 270px;
    padding: 30px;
    margin: 0px auto;
    background-color: $pure-white;
    box-shadow: 0px 1px 5px 0px rgba(0, 27, 103, 0.15);
    > .nav {
      margin-bottom: 100px;
      border: 0px;
      > .nav-item {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0px;
        }
        > .nav-link {
          padding: 0px;
          color: #687596;
          font-size: 18px;
          position: relative;
          display: inline-block;
          border: 0px;
          &:before {
            top: 0px;
            opacity: 0;
            width: 2px;
            left: -30px;
            bottom: 0px;
            height: 100%;
            content: "";
            position: absolute;
            background-color: $default-blue;
            transition: $default-transition;
          }
          &:hover {
            color: $default-blue;
            &:before {
              opacity: 1;
            }
          }
        }
        > .nav-link.active {
          color: $default-blue;
          &:before {
            opacity: 1;
          }
        }
      }
    }

    > .contact-info {
      > li {
        font-size: 16px;
        > a {
          color: $default-blue;
          text-transform: lowercase;
        }
      }
    }
  }

  .tab-content {
    padding-left: 35px;
    padding-right: 70px;
  }
}
/* faq-section - end */

/* 7.5 - home page 5 - end
================================================== */

/* 7.6 home page 6 - start
================================================== */

/* banner-section - start */
.banner-section {
  .mobile-app-banner-6 {
    z-index: 1;
    width: 100%;
    height: auto;
    padding-top: 150px;
    .banner-content {
      z-index: 1;
      padding-top: 210px;
      h2 {
        font-size: 46px;
        line-height: 60px;
        margin-bottom: 30px;
      }
      p {
        font-size: 16px;
        margin-bottom: 70px;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .banner-image {
      .phone-image {
        width: 458px;
        margin-right: -176px;
        position: static !important;
      }
      .commentbar-image-1 {
        width: 400px;
        position: absolute;
        top: 140px !important;
        left: -110px !important;
      }
      .commentbar-image-2 {
        width: 400px;
        position: absolute;
        top: 365px !important;
        left: -70px !important;
      }
    }
    .shape-bg-image {
      top: -255px;
      z-index: -1;
      right: -315px;
      width: 1255px;
      position: absolute;
    }
  }
}
/* banner-section - end */

/* service-section - start */
.service-section {
  .service-grid-item {
    padding: 60px 30px;
    border: 1px solid transparent;
    background-color: $pure-white;
    transition: $default-transition;
    .item-icon {
      width: 105px;
      height: 105px;
      display: table;
      font-size: 36px;
      color: $pure-white;
      position: relative;
      text-align: center;
      margin: 0 auto 30px;
      background-repeat: no-repeat;
      background-position: center center;
      > i {
        top: 65%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -65%);
      }
    }
    .item-title {
      font-size: 21px;
      font-weight: $fw-bold;
    }
    p {
      font-size: 16px;
      margin-bottom: 0px;
    }
    &:hover {
      border-color: $deep-gray;
      box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
    }
  }
}
/* service-section - end */

/* features-section - start */
.home-page-6 {
  .features-section {
    .feature-item {
      .feature-content {
        padding: 0px;
        padding-top: 100px;
      }
    }
  }
}
.features-section {
  .feature-item {
    .feature-image-6 {
      padding-top: 120px;
      .popup-video {
        top: 280px;
        z-index: 1;
        right: 0px;
        width: 95px;
        height: 95px;
        font-size: 24px;
        line-height: 95px;
        position: absolute;
        text-align: center;
        border-radius: 100%;
        color: $default-pink;
        background-color: $pure-white;
        animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
        &:before {
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: -1;
          content: "";
          position: absolute;
          border-radius: 100%;
          box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
        }
      }
      .bg-image {
        width: 570px;
        margin-left: -120px;
      }
      .phone-image {
        top: 0px;
        z-index: 1;
        left: -35px;
        width: 425px;
        position: absolute;
      }
      .comment-image-1,
      .comment-image-2 {
        z-index: 1;
        width: 400px;
        position: absolute;
      }
      .comment-image-1 {
        top: 180px;
        left: 70px;
      }
      .comment-image-2 {
        left: 115px;
        bottom: 100px;
      }
      .shape-image-1 {
        top: 30px;
        left: -40px;
        position: absolute;
      }

      .shape-image-2 {
        top: 52%;
        right: 100px;
        position: absolute;
      }
      .shape-image-3 {
        left: -30px;
        bottom: 10px;
        position: absolute;
      }
    }

    &:nth-child(2) {
      .feature-image-6 {
        .bg-image {
          margin-left: 0px;
          margin-right: -120px;
        }
        .phone-image {
          left: unset;
          right: -35px;
        }
        .comment-image-1,
        .comment-image-2 {
          z-index: 1;
          width: 400px;
          position: absolute;
        }
        .comment-image-1 {
          top: 70px;
        }
        .comment-image-2 {
          left: 270px;
        }
        .shape-image-1 {
          left: 100px;
        }
        .shape-image-2 {
          right: -40px;
        }
        .shape-image-3 {
          left: 120px;
        }
      }
    }
  }
}
/* features-section - end */

/* working-process-section - start */
.working-process-section {
  .process-item {
    z-index: 2;
    padding: 30px;
    margin-top: 30px;
    margin-left: -30px;
    margin-right: -30px;
    position: relative;
    border-radius: 5px;
    transition: $default-transition;
    &:hover {
      background-color: $pure-white;
      box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
    }
    .item-icon {
      float: left;
      width: 70px;
      height: 115px;
      margin-right: 30px;
      line-height: 115px;
      text-align: center;
    }
    .item-content {
      display: table;
      .item-title {
        font-size: 21px;
        margin-bottom: 20px;
        font-weight: $fw-bold;
      }
    }
  }

  .process-phone-image {
    padding-top: 100px;
    .bg-image {
      z-index: -1;
    }
    .phone-image {
      top: 0px;
      left: 50%;
      z-index: 1;
      width: 295px;
      position: absolute;
      transform: translateX(-50%);
    }
    .shape-image-1 {
      top: 150px;
      left: 35px;
      width: 70px;
      position: absolute;
    }
    .shape-image-2 {
      width: 70px;
      right: 35px;
      bottom: 110px;
      position: absolute;
    }
  }

  .seemore-btn {
    margin-top: 45px;
  }
}
/* working-process-section - end */

/* 7.6 home page 6 - end
================================================== */

/* 7 - mobile app -->> home page - end
==================================================================================================== */

/* 8 - sass landing -->> home page - start
==================================================================================================== */

/* 8.1 - home page 7 - start
================================================== */

/* header-section - start */
.home-page-7 {
  .sticky-header.stuck {
    &:before {
      background-color: #1e2130;
    }
  }
}
/* header-section - end */

/* banner-section - start */
.banner-section {
  position: relative;
  .sass-banner-1 {
    width: 100%;
    height: 100%;
    padding-top: 250px;
    .banner-content {
      z-index: 1;
      color: $pure-white;
      position: relative;
      .title-text {
        font-size: 36px;
        color: $pure-white;
        text-transform: initial;
      }
      p {
        width: 630px;
        margin: 0 auto;
        font-size: 16px;
        margin-bottom: 40px;
      }
      .newsletter-form {
        .form-item {
          width: 430px;
          margin: 0 auto;
          margin-bottom: 20px;
          input {
            height: 60px;
          }
          .submit-btn {
            top: 5px;
            right: 5px;
            z-index: 1;
            height: 50px;
            padding: 0px 40px;
            line-height: 50px;
            color: $pure-white;
            position: absolute;
            text-align: center;
            border-radius: 3px;
          }
        }
      }
      .info-list {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
    .banner-item-image {
      z-index: 2;
      position: relative;
      margin-bottom: -220px;
      .child-img-1 {
        top: -14px;
        left: 364px;
        width: 395px;
        position: absolute;
      }
      .child-img-2 {
        left: 0px;
        bottom: -17px;
        width: 407px;
        position: absolute;
      }
      .child-img-3 {
        right: 60px;
        bottom: -17px;
        width: 407px;
        position: absolute;
      }
    }
  }
}

.home-page-7 {
  .banner-section {
    .decoration-items {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow: hidden;
      position: absolute;
      .big-circle-shape {
        left: 50%;
        width: 1380px;
        height: 1380px;
        bottom: -690px;
        overflow: hidden;
        position: absolute;
        border-radius: 100%;
        transform: translateX(-50%);
        border: 1px solid rgba(255, 255, 255, 0.05);
        &:before,
        &:after {
          top: -3px;
          left: -3px;
          opacity: 0;
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
          position: absolute;
          animation: ripple infinite 4s;
          background-color: rgba(255, 255, 255, 0.05);
        }
        &:after {
          animation-delay: 0.8s;
        }
      }

      .cross-image {
        top: 55%;
        z-index: 1;
        left: 210px;
        position: absolute;
        animation: spin 5s infinite linear;
      }
      .flow-image-1 {
        top: 220px;
        z-index: 1;
        left: 385px;
        position: absolute;
        animation: zoominout 1.5s alternate infinite ease-in;
      }
      .flow-image-2 {
        z-index: 1;
        left: 370px;
        bottom: 170px;
        position: absolute;
        animation: zoominout 1.5s alternate infinite ease-in;
      }
      .circle-image-1 {
        top: 45%;
        left: 27%;
        z-index: 1;
        position: absolute;
        animation: spin 5s infinite linear;
      }
      .flow-image-3 {
        left: 55%;
        top: 165px;
        z-index: 1;
        position: absolute;
        animation: zoominout 1.5s alternate infinite ease-in;
      }
      .circle-image-2 {
        top: 48%;
        left: 80%;
        z-index: 1;
        position: absolute;
        animation: spin 5s infinite linear;
      }
      .circle-image-3 {
        z-index: 1;
        top: 215px;
        right: 300px;
        position: absolute;
        animation: zoominout 1.5s alternate infinite ease-in;
      }
      .box-image {
        z-index: 1;
        right: 160px;
        bottom: 270px;
        position: absolute;
        animation: spin 5s infinite linear;
      }

      .dark-box-1,
      .dark-box-2,
      .dark-box-3,
      .dark-box-4 {
        z-index: 0;
        position: absolute;
        /* animation: zoominout 15s alternate infinite ease-in; */
      }

      .dark-box-1 {
        top: 37%;
        left: 16%;
      }
      .dark-box-2 {
        top: 31%;
        left: 13%;
      }
      .dark-box-3 {
        top: 215px;
        right: 260px;
      }
      .dark-box-4 {
        bottom: 0px;
        right: 170px;
      }
    }
  }
}
/* banner-section - end */

/* client-section - start */
.home-page-7 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -30px;
  }
}
/* client-section - end */

/* features-section - start */
.features-section {
  .feature-item {
    .feature-image-7 {
      position: relative;
      .bg-image {
        width: 530px;
        right: -260px;
        position: relative;
      }
      .big-chart {
        top: 80px;
        width: 600px;
        right: -150px;
        position: absolute;
      }
      .circle-chart-1 {
        width: 40px;
        right: 135px;
        bottom: 60px;
        position: absolute;
      }
      .circle-chart-2 {
        width: 40px;
        right: 90px;
        bottom: 60px;
        position: absolute;
      }
      .circle-chart-3 {
        width: 40px;
        right: 45px;
        bottom: 60px;
        position: absolute;
      }
      .man-image-1 {
        left: 160px;
        width: 160px;
        bottom: 65px;
        position: absolute;
      }
      .man-image-2 {
        left: -25px;
        bottom: 65px;
        width: 205px;
        position: absolute;
      }
      .man-image-3 {
        z-index: 1;
        bottom: 60px;
        width: 165px;
        right: -140px;
        position: absolute;
      }
      .leaf-image {
        width: 75px;
        bottom: 60px;
        right: -175px;
        position: absolute;
      }
    }

    &:nth-child(2) {
      .feature-image-7 {
        .bg-image {
          width: 512px;
          right: unset;
          left: -245px;
          position: relative;
        }
        .big-chart {
          top: 108px;
          width: 540px;
          left: -170px;
          right: unset;
        }
        .man-image-1 {
          top: 0px;
          left: 0px;
          width: 312px;
          bottom: unset;
        }
        .man-image-2 {
          left: -230px;
          bottom: 30px;
          width: 195px;
        }
        .man-image-3 {
          right: 130px;
          bottom: 30px;
          width: 360px;
        }
        .leaf-image {
          right: 75px;
          z-index: -1;
          bottom: 30px;
          width: 220px;
        }
      }
    }
  }
}
/* features-section - end */

/* software-section - start */
.software-section {
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .shape-img-1,
  .shape-img-2 {
    width: 579px;
    position: absolute;
  }
  .shape-img-1 {
    top: 0px;
    left: 0px;
  }
  .shape-img-2 {
    right: 0px;
    bottom: 0px;
  }

  .section-title {
    color: $pure-white;
    .title-text {
      color: $pure-white;
    }
  }

  .software-container {
    width: 100%;
    height: 460px;
    display: table;
    position: relative;
    .software-item {
      z-index: 1;
      width: 134px;
      height: 134px;
      position: absolute;
      &:before {
        top: 50%;
        left: 50%;
        width: 90%;
        height: 90%;
        opacity: 0;
        content: "";
        z-index: -1;
        position: absolute;
        border-radius: 100%;
        border: 2px solid $default-blue;
        transform: translate(-50%, -50%);
        transition: $default-transition;
      }
      &:hover {
        &:before {
          opacity: 1;
          width: 130%;
          height: 130%;
        }
      }
      .software-logo {
        width: 100%;
        height: 100%;
        padding: 35px;
        display: block;
        overflow: hidden;
        border-radius: 100%;
        background-color: $pure-white;
        box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
      }

      &:nth-child(1) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:nth-child(2) {
        top: 80px;
        left: -26px;
        width: 100px;
        height: 100px;
        .software-logo {
          padding: 30px;
        }
      }
      &:nth-child(3) {
        left: 100px;
        bottom: 50px;
        width: 130px;
        height: 130px;
      }
      &:nth-child(4) {
        top: 0px;
        left: 245px;
        width: 75px;
        height: 75px;
        .software-logo {
          padding: 20px;
        }
      }
      &:nth-child(5) {
        bottom: 0px;
        left: 380px;
        width: 88px;
        height: 88px;
        .software-logo {
          padding: 25px;
        }
      }
      &:nth-child(6) {
        top: 30px;
        right: 100px;
        width: 110px;
        height: 110px;
        .software-logo {
          padding: 20px;
        }
      }
      &:nth-child(7) {
        bottom: 60px;
        right: -57px;
        width: 112px;
        height: 112px;
        .software-logo {
          padding: 25px;
        }
      }
    }
  }
}
/* software-section - end */

/* software-section - start */
.pricing-section {
  .bg-image {
    position: relative;
    .bg-shape-1 {
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      position: absolute;
    }
  }
}
/* software-section - end */

/* free-trail-section - start */
.free-trail-section {
  .section-title {
    .title-text {
      span {
        small {
          color: $default-blue;
          font-weight: $fw-bold;
          font-family: $heading-font;
        }
      }
    }
  }

  .free-trail-form {
    overflow: hidden;
    border-radius: 5px;
    padding: 50px 170px;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
    .form-item {
      margin-bottom: 15px;
      input {
        width: 100%;
        height: 70px;
        display: table;
        padding: 0px 30px;
        border-radius: 3px;
        border: 1px solid $deep-gray;
        background-color: $pure-white;
        &:focus {
          border-color: $default-blue;
        }
      }
      .form-item-btn {
        top: 50%;
        right: 10px;
        width: auto;
        height: 50px;
        line-height: 50px;
        padding: 0px 40px;
        color: $pure-white;
        text-align: center;
        border-radius: 3px;
        position: absolute;
        transform: translateY(-50%);
      }
    }
    p {
      font-weight: $fw-medium;
    }
  }
}
/* free-trail-section - end */

/* 8.1 - home page 7 - end
================================================== */

/* 8.2 - home page 8 - start
================================================== */

/* banner-section - start */
.banner-section {
  .sass-banner-2 {
    overflow: hidden;
    padding-top: 195px;
    padding-bottom: 65px;
    .banner-content {
      padding-top: 150px;
      .title-text {
        font-size: 36px;
        line-height: 48px;
      }
      p {
        font-size: 16px;
        max-width: 400px;
        margin-bottom: 50px;
        color: $default-color;
      }
      .newsletter-form {
        .form-item {
          width: 430px;
          margin-bottom: 20px;
          input {
            height: 60px;
          }
          .submit-btn {
            top: 5px;
            right: 5px;
            z-index: 1;
            height: 50px;
            padding: 0px 40px;
            line-height: 50px;
            color: $pure-white;
            position: absolute;
            text-align: center;
            border-radius: 3px;
          }
        }
        p {
          font-size: 14px;
          font-weight: $fw-medium;
        }
      }
    }
    .banner-item-image {
      position: relative;
      .bg-image {
        width: 760px;
        margin-left: -16px;
        margin-right: -170px;
      }
      .big-image {
        z-index: 1;
        bottom: 60px;
        width: 810px;
        right: -190px;
        position: absolute;
        top: unset !important;
        left: unset !important;
      }
      .man-image-1 {
        z-index: 1;
        width: 150px;
        bottom: 61px;
        position: absolute;
        top: unset !important;
        left: -60px !important;
      }
      .man-image-2 {
        z-index: 1;
        right: -90px;
        width: 295px;
        bottom: 61px;
        position: absolute;
        top: unset !important;
        left: unset !important;
      }
      .man-image-3 {
        z-index: 1;
        right: 5px;
        width: 165px;
        position: absolute;
        top: 65px !important;
        left: unset !important;
      }
      .man-image-4 {
        z-index: 1;
        width: 160px;
        position: absolute;
        top: 54px !important;
        left: 170px !important;
      }
      .man-image-5 {
        z-index: 1;
        width: 130px;
        bottom: 61px;
        position: absolute;
        top: unset !important;
        left: 200px !important;
      }
      .leaf-image-1 {
        width: 230px;
        bottom: 63px;
        right: -180px;
        position: absolute;
        top: unset !important;
        left: unset !important;
      }
      .leaf-image-2 {
        z-index: 0;
        width: 170px;
        bottom: 190px;
        position: absolute;
        left: 90px !important;
        top: unset !important;
      }
      .comment-image {
        z-index: 1;
        width: 125px;
        position: absolute;
        top: 220px !important;
        left: 20px !important;
      }
      .share-image {
        right: 60px;
        width: 75px;
        position: absolute;
        top: 0px !important;
        left: unset !important;
      }
      .cloud-image-1 {
        z-index: 1;
        width: 170px;
        position: absolute;
        top: 40px !important;
        left: 40px !important;
      }
      .cloud-image-2,
      .cloud-image-3 {
        z-index: 1;
        width: 95px;
        position: absolute;
      }
      .cloud-image-2 {
        right: 166px;
        top: 40px !important;
        left: unset !important;
      }
      .cloud-image-3 {
        right: -80px;
        top: 120px !important;
        left: unset !important;
      }

      .cloud-image-4,
      .cloud-image-5 {
        z-index: 1;
        width: 65px;
        position: absolute;
      }
      .cloud-image-4 {
        top: 180px !important;
        left: -25px !important;
      }
      .cloud-image-5 {
        right: -120px;
        top: 210px !important;
        left: unset !important;
      }
    }
  }
}
/* banner-section - end */

/* client-section - start */
.home-page-8 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -20px;
  }
}
/* client-section - end */

/* software-section - start */
.home-page-8 {
  .software-section {
    .section-title {
      .title-text {
        color: $default-black;
      }
      .paragraph-text {
        color: $default-color;
      }
    }
  }
}
/* software-section - end */

/* features-section - start */
.home-page-8 {
  .features-section {
    .feature-item {
      &:nth-child(1) {
        margin-bottom: 200px;
      }
      &:nth-child(2) {
        margin-bottom: 265px;
      }
    }
  }
}
.features-section {
  .feature-item {
    margin-top: -100px;
    .feature-image-8 {
      position: relative;
      .image-1 {
        width: 838px;
        margin-right: -368px;
        img {
          width: 100%;
          display: block;
        }
      }
      .image-2 {
        left: 30px;
        top: -125px;
        position: absolute;
      }
      .image-3 {
        right: 0px;
        bottom: -183px;
        position: absolute;
      }
    }

    &:nth-child(3) {
      .feature-image-8 {
        position: relative;
        .image-1 {
          width: 838px;
          margin-left: -368px;
          img {
            width: 100%;
            display: block;
          }
        }
        .image-2 {
          top: -65px;
          left: -65px;
          position: absolute;
        }
        .image-3 {
          right: -65px;
          bottom: 35px;
          position: absolute;
        }
      }
    }
  }
}
/* features-section - end */

/* 8.2 - home page 8 - end
================================================== */

/* 8.3 - home page 9 - start
================================================== */

/* header-section - start */
.home-page-9 {
  .sticky-header.stuck {
    &:before {
      background: linear-gradient(to right, #05b5fd, #75e6ca);
    }
  }
}
/* header-section - end */

/* banner-section - start */
.banner-section {
  .sass-banner-3 {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 315px;
    padding-bottom: 75px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
    .banner-content {
      color: $pure-white;
      padding-top: 80px;
      .title-text {
        font-size: 47px;
        line-height: 60px;
        color: $pure-white;
        text-transform: initial;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 70px;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
            .info-text {
              display: table;
              font-size: 16px;
              font-weight: $fw-medium;
              span {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .banner-item-image {
      position: relative;
      .laptop-image {
        width: 930px;
        margin-left: -110px;
        img {
          width: 100%;
          display: block;
        }
      }
      .child-image-1 {
        left: 50px;
        top: -110px;
        position: absolute;
      }
      .child-image-2 {
        bottom: 0px;
        right: -285px;
        position: absolute;
      }
    }

    .shape-1 {
      top: 50%;
      left: 210px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-2 {
      top: 215px;
      left: 390px;
      position: absolute;
      animation: zoominout 1s alternate infinite ease-in;
    }
    .shape-3 {
      left: 480px;
      bottom: 105px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-4 {
      top: 265px;
      left: 650px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-5 {
      left: 920px;
      bottom: 270px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-6 {
      top: 195px;
      left: 1045px;
      position: absolute;
      animation: zoominout 1s alternate infinite ease-in;
    }
    .shape-7 {
      top: 215px;
      right: 305px;
      position: absolute;
      animation: zoominout 1s alternate infinite ease-in;
    }
  }
}
/* banner-section - end */

/* client-section - start */
.home-page-9 {
  .client-section {
    z-index: 1;
    position: relative;
    margin-bottom: -20px;
  }
}
/* client-section - end */

/* software-section - start */
.home-page-9 {
  .software-section {
    .section-title {
      .title-text {
        color: $default-black;
      }
      .paragraph-text {
        color: $default-black;
      }
    }
  }
}
/* software-section - end */

/* 8.3 - home page 9 - end
================================================== */

/* 8.4 - home page 10 - start
================================================== */

/* banner-section - start */
.banner-section {
  .sass-banner-4 {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    padding-top: 315px;
    .banner-content {
      padding-top: 80px;
      .title-text {
        font-size: 47px;
        line-height: 60px;
        text-transform: initial;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 70px;
        color: $default-color;
      }
      .btns-group {
        > ul {
          > li {
            margin-right: 30px;
            &:last-child {
              margin-right: 0px;
            }
            .custom-btn {
              background-color: $default-blue;
              &:before {
                background-color: $default-pink;
              }
            }
            .info-text {
              display: table;
              font-size: 16px;
              padding: 13px 0px;
              font-weight: $fw-medium;
            }
          }
        }
      }
    }

    .banner-item-image {
      position: relative;
      .popup-video {
        top: 36%;
        left: 114%;
        z-index: 30;
        width: 95px;
        height: 95px;
        font-size: 24px;
        line-height: 95px;
        position: absolute;
        text-align: center;
        border-radius: 100%;
        color: $default-pink;
        background-color: $pure-white;
        animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
        &:before {
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: -1;
          content: "";
          position: absolute;
          border-radius: 100%;
          box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
        }
      }
      .laptop-image {
        z-index: 2;
        width: 1000px;
        margin-left: 60px;
        position: relative;
        img {
          width: 100%;
          display: block;
        }
      }
      .bg-image {
        z-index: -1;
        top: -580px;
        right: -693px;
        width: 1100px;
        position: absolute;
      }
      .shape-image {
        top: -70px;
        left: 90px;
        z-index: 1;
        position: absolute;
      }
    }

    .shape-1 {
      top: 50%;
      left: 210px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-2 {
      top: 215px;
      left: 390px;
      position: absolute;
      animation: zoominout 1s alternate infinite ease-in;
    }
    .shape-3 {
      left: 480px;
      bottom: 105px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-4 {
      top: 265px;
      left: 650px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-5 {
      left: 920px;
      bottom: 270px;
      position: absolute;
      animation: spin 5s infinite linear;
    }
    .shape-6 {
      top: 195px;
      left: 1045px;
      position: absolute;
      animation: zoominout 1s alternate infinite ease-in;
    }
  }
}
/* banner-section - end */

/* client-section - start */
.client-section {
  .title-paragraph-text {
    display: table;
    font-size: 16px;
    max-width: 430px;
    text-align: center;
    margin: 0 auto 70px;
    font-weight: $fw-medium;
  }
}
/* client-section - end */

/* service-section - start */
.home-page-10 {
  .service-section {
    .container {
      max-width: 1200px;
    }
    .service-grid-item {
      border-color: $deep-gray;
      &:hover {
        border-color: $pure-white;
      }
    }
  }
}
/* service-section - end */

/* features-section - start */
.features-section {
  .feature-item {
    .feature-image-10 {
      position: relative;
      .big-image {
        z-index: 1;
        width: 580px;
        position: relative;
        margin-left: -135px;
      }
      .small-image-1 {
        top: 76px;
        z-index: 2;
        left: -205px;
        position: absolute;
      }
      .small-image-2 {
        z-index: 2;
        right: -20px;
        bottom: -75px;
        position: absolute;
      }
      .shape-1 {
        z-index: 2;
        top: -33px;
        left: -67px;
        position: absolute;
      }
      .shape-2 {
        left: 0px;
        bottom: 0px;
        position: absolute;
      }
    }

    &:nth-child(2) {
      .feature-image-10 {
        .big-image {
          margin-left: 0px;
          margin-right: -135px;
        }
        .small-image-1 {
          top: 42px;
          z-index: 2;
          left: 60px;
          position: absolute;
        }
        .shape-2 {
          z-index: 2;
          left: 190px;
          bottom: 10px;
          position: absolute;
        }
        .shape-3 {
          top: 56%;
          z-index: 2;
          width: 50px;
          right: -97px;
          position: absolute;
        }
      }
    }
  }
}
/* features-section - end */

/* 8.4 - home page 10 - end
================================================== */

/* 8 - sass landing -->> home page - end
==================================================================================================== */

/* 9 - app store -->> home page - end
==================================================================================================== */

/* 9.1 - home page 11 - start
================================================== */

/* header-section - start */
.header-section {
  .btns-group {
    > ul {
      > li {
        position: relative;
        .search-btn {
          float: left;
          width: 50px;
          height: 50px;
          font-size: 18px;
          line-height: 60px;
          text-align: center;
          margin-right: 30px;
          color: $default-black;
          &:hover {
            color: $default-pink;
          }
        }
        /* main-search-body - start */
        .main-search-body {
          top: 50px;
          right: 14px;
          opacity: 0;
          width: 300px;
          padding: 10px;
          border-radius: 5px;
          position: absolute;
          visibility: hidden;
          border-top-right-radius: 0px;
          background-color: $pure-white;
          transition: $default-transition;
          .search-input {
            width: 100%;
            margin: 0px;
            height: 50px;
            padding: 0px 20px;
            border-radius: 5px;
            padding-right: 40px;
            border: 1px solid #f4f2ff;
            border-top-right-radius: 0px;
            background-color: $light-gray;
            transition: $default-transition;
            &:focus {
              outline: none;
              border-color: $default-pink;
              background-color: $pure-white;
            }
          }
          .search-close {
            top: 50%;
            z-index: 1;
            width: auto;
            right: 31px;
            height: auto;
            line-height: 1;
            font-size: 14px;
            margin-right: 0px;
            position: absolute;
            color: $default-color;
            transform: translateY(-50%);
            &:hover {
              color: $default-pink;
            }
          }
        }
        .main-search-body.search-open {
          opacity: 1;
          visibility: visible;
        }
        /* main-search-body - end */
      }
    }
  }
}
/* header-section - end */

/* slider-section - start */
.home-page-11 {
  .slider-section {
    height: auto;
  }
}
.slider-section {
  .appstore-main-carousel {
    padding-top: 186px;
    padding-bottom: 30px;
    background-color: $light-gray;
    .item {
      .item-content {
        padding-top: 150px;
        h1 {
          font-size: 48px;
          line-height: 60px;
          margin-bottom: 40px;
        }
        p {
          font-size: 16px;
          max-width: 600px;
          line-height: 28px;
          margin-bottom: 50px;
        }
        .btns-group {
          > ul {
            > li {
              margin-right: 25px;
              &:last-child {
                margin-right: 0px;
              }
              .store-btn {
                padding: 15px 20px;
              }
            }
          }
        }
      }
      .item-image {
        padding-top: 85px;
        position: relative;
        padding-bottom: 100px;
        .bg-image {
          z-index: 0;
          width: 416px;
          position: relative;
          margin-right: -72px;
        }
        .phone-image-1 {
          right: 0px;
          z-index: 1;
          bottom: 10px;
          width: 295px;
          position: absolute;
        }
        .man-image-1 {
          z-index: 1;
          bottom: 0px;
          width: 185px;
          right: -110px;
          position: absolute;
        }
        .man-image-2 {
          left: 0px;
          z-index: 1;
          bottom: 0px;
          width: 140px;
          position: absolute;
        }
        .comment-image {
          top: 35%;
          left: 44%;
          z-index: 1;
          width: 120px;
          position: absolute;
        }
        .leaf-image-1 {
          top: 0px;
          left: 10px;
          width: 180px;
          position: absolute;
        }
        .leaf-image-2 {
          top: 46px;
          width: 215px;
          right: -110px;
          position: absolute;
          transform: rotate(-30deg);
        }
        .phone-image-2 {
          width: 390px;
          right: -70px;
          bottom: 65px;
          position: absolute;
        }
      }
    }
  }
}

.home-page-11 {
  .slider-section {
    .custom-pagination {
      .swiper-button-prev,
      .swiper-button-next {
        margin: 0px;
        width: 45px;
        height: 45px;
        // left: 44.55%;
        @media screen and (max-width: 991px) {
          // left: calc(50% - 30px);
          // transform: translateX(-50%);
        }
        top: auto;
        bottom: 0px;
        &:before {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          font-size: 16px;
          font-weight: 900;
          line-height: 45px;
          text-align: center;
          position: absolute;
          border-radius: 100%;
          color: $default-black;
          font-family: "unicons";
          transition: $default-transition;
          border: 1px solid $default-black;
        }
        &:hover {
          background: none;
          &:before {
            color: $pure-white;
            background: $default-blue;
            border-color: $default-blue;
          }
        }

        &::after {
          display: none;
        }
      }

      .swiper-button-prev {
        &:before {
          content: "\e82a";
        }
      }
      .swiper-button-next {
        // left: calc(44.55% + 65px);
        // @media screen and (max-width: 991px) {
        //   left: calc(50% + 30px);
        // }
        right: auto;
        &:before {
          content: "\e82c";
        }
      }
    }

    .custom-pagination {
      .swiper-button-prev,
      .swiper-button-next {
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }
      .swiper-button-prev {
        left: 40px;
      }
      .swiper-button-next {
        right: 40px;
      }
    }
  }
}
/* slider-section - end */

/* mobile-app-section - start */
.mobile-app-section {
  .section-title {
    .title-text {
      font-size: 36px;
      font-weight: $fw-medium;
    }
    .custom-btn-underline {
      margin: 12px 0px;
    }
  }
}
/* mobile-app-section - end */

/* app-Category-section - start */
.app-Category-section {
  .Category-item {
    margin-top: 40px;
    .item-title {
      font-size: 21px;
      margin-bottom: 50px;
    }
    > ul {
      > li {
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0px;
        }
        > a {
          font-size: 16px;
          color: $default-color;
          font-weight: $fw-medium;
          &:hover {
            color: $default-blue;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
/* app-Category-section - end */

/* 9.1 - home page 11 - end
================================================== */

/* 9.2 - home page 12 - start
================================================== */

/* header-section - start */
.home-page-12,
.app-details-page-2 {
  padding-top: 156px;
  .header-section.black-content {
    background-color: $pure-white;
  }
}
/* header-section - end */

/* search-section - start */
.search-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .overlay-color {
    padding: 240px 0px;
    background-image: linear-gradient(
      to right,
      rgba(0, 57, 113, 0.9),
      rgba(99, 5, 134, 0.9)
    );
  }
  .banner-content {
    h1 {
      font-size: 48px;
      color: $pure-black;
      margin-bottom: 70px;
    }
    .search-form {
      .form-item {
        position: relative;
        input {
          height: 70px;
          padding: 0px 35px;
        }
        .search-btn {
          top: 50%;
          right: 35px;
          font-size: 24px;
          position: absolute;
          color: $default-blue;
          transform: translateY(-50%);
        }
      }
    }
  }
}
/* search-section - end */

/* mobile-app-section - start */
.mobile-app-section {
  .app-nav {
    .nav-title {
      font-size: 16px;
      padding: 20px 40px;
      color: $pure-white;
      font-weight: $fw-medium;
      background-color: $default-blue;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    > ul {
      border-top: none;
      padding: 25px 0px;
      border: 1px solid $deep-gray;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      > li {
        > a {
          font-size: 16px;
          position: relative;
          padding: 15px 40px;
          color: $default-color;
          font-weight: $fw-medium;
          &:before {
            top: 0px;
            left: -1px;
            opacity: 0;
            width: 3px;
            bottom: 0px;
            content: "";
            position: absolute;
            background-color: $default-blue;
            transition: $default-transition;
          }
          &:hover {
            color: $default-blue;
            &:before {
              opacity: 1;
            }
          }
        }
        > .active {
          color: $default-blue;
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  .tab-content {
    .pagination-nav {
      margin-top: 50px;
    }
    .tab-pane {
      .top-content-bar {
        padding: 20px 0px;
        margin-bottom: 50px;
        .tab-pane-title {
          font-size: 24px;
        }
        .option-form {
          .form-control {
            height: 60px;
            font-size: 18px;
            padding: 0px 20px;
            margin: -16px 0px;
            border-radius: 3px;
            color: $default-black;
            font-weight: $fw-medium;
            text-transform: capitalize;
            border: 1px solid $deep-gray;
          }
        }
      }

      .app-item {
        width: 100%;
        margin: 0px;
        margin-bottom: 50px;
      }
    }
  }
}
/* mobile-app-section - end */

/* 9.2 - home page 12 - end
================================================== */

/* 9 - app store -->> home page - end
==================================================================================================== */
